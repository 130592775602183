import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Member } from "../domain/entities/Member";
import { MemberPayment } from "../domain/entities/MemberPayment";
import { acceptMemberThunk, countMembersThunk, countPendingMembersThunk, createMemberThunk, deactivateMemberThunk, deleteMemberThunk, findFamilyMembersThunk, findMemberByIdThunk, getMemberQuotaThunk, getMembersThunk, reactivateMemberThunk, updateFactoryCardThunk, updateMemberThunk, findMemberInvitationsThunk, sendInvitationThunk } from './memberThunk';
import { CreateMemberPassDto, MemberFiltersDto } from './memberDto';
import { Invitation } from '../domain/entities/Invitation';

export type MemberAction = 'none' | 'create' | 'edit_profile_info' | 'edit_contact_info' | 'create_password' | 'admin_member_profile' | 'admin_member_items' | 'editing_status' | 'password_required';

interface MemberState {
    action: MemberAction,
    createMemberPass?: CreateMemberPassDto,
    members: Member[],
    memberInvitations: Invitation[],
    memberSelected?: Member,
    memberSelectedFamily: Member[],
    memberSelectedQuota: MemberPayment[],
    pendingMembersCount: Number,
    status: 'loading' | 'ready' | 'error',
    total: Number,
    filters: MemberFiltersDto
}

export const initialState: MemberState = {
    action: 'none',
    createMemberPass: undefined,
    members: [],
    memberInvitations: [],
    memberSelected: undefined,
    memberSelectedFamily: [],
    memberSelectedQuota: [],
    pendingMembersCount: undefined,
    status: 'ready',
    total: 0,
    filters: {
        fromDate: undefined,
        toDate: undefined,
        status: undefined,
        searchText: undefined,
        limit: 10,
        offset: 0,
        paymentMethod: undefined
    }
}


const memberSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {

        selectMember: (state, action: PayloadAction<Member | undefined>) => {
            state.memberSelected = action.payload;
        },

        changeAction: (state, action: PayloadAction<MemberAction>) => {
            state.action = action.payload;
        },

        changeCreateMemberPass: (state, action: PayloadAction<CreateMemberPassDto | null>) => {
            if (action === null) {
                state.action = 'none';
                state.createMemberPass = undefined;
            } else {
                state.action = 'create_password';
                state.createMemberPass = action.payload;
            }
        },

        changeFilterSearchText: (state: Draft<MemberState>, action: PayloadAction<string | undefined>) => {
            state.filters.searchText = action.payload;
        },

        changeFilterFromDate: (state: Draft<MemberState>, action: PayloadAction<Date | undefined>) => {
            state.filters.fromDate = action.payload ?? undefined;
        },

        changeFilterToDate: (state: Draft<MemberState>, action: PayloadAction<Date | undefined>) => {
            state.filters.toDate = action.payload ?? undefined;
        },

        changeFilterStatus: (state: Draft<MemberState>, action: PayloadAction<string | undefined>) => {
            state.filters.status = action.payload;
        },

        changeFilterLimit: (state: Draft<MemberState>, action: PayloadAction<number | undefined>) => {
            state.filters.limit = action.payload;
        },

        changeFilterOffset: (state: Draft<MemberState>, action: PayloadAction<number | undefined>) => {
            state.filters.offset = action.payload;
        },
        changePaymentMethod: (state: Draft<MemberState>, action: PayloadAction<string | undefined>) => {
            state.filters.paymentMethod = action.payload ?? undefined;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<MemberState>) => {

        /* GET MEMBERS */
        builder.addCase(getMembersThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getMembersThunk.fulfilled, (state, action: PayloadAction<Member[]>) => {
            state.members = action.payload;
            state.status = 'ready';
        }).addCase(getMembersThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* COUNT MEMBERS */
        builder.addCase(countMembersThunk.pending, () => {
        }).addCase(countMembersThunk.fulfilled, (state, action: PayloadAction<Number>) => {
            state.total = action.payload;
        }).addCase(countMembersThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* COUNT PENDING MEMBERS */
        builder.addCase(countPendingMembersThunk.pending, (state) => {
        }).addCase(countPendingMembersThunk.fulfilled, (state, action: PayloadAction<Number>) => {
            state.pendingMembersCount = action.payload;
        }).addCase(countPendingMembersThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* DELETE MEMBER */
        builder.addCase(deleteMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.status = 'ready';
            state.members = state.members.filter(member => member.id !== action.payload.id);
            if (state.memberSelected?.id === action.payload.id) {
                state.memberSelected = undefined
            }
        }).addCase(deleteMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* FIND MEMBER BY ID  */
        builder.addCase(findMemberByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findMemberByIdThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.status = 'ready';
            state.memberSelected = action.payload;
        }).addCase(findMemberByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        /* UPDATE MEMBER */
        builder.addCase(updateMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.members = state.members.map(member => member.id.equals(action.payload.id) ? action.payload : member);
            if (state.memberSelected?.id.equals(action.payload.id)) {
                state.memberSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(updateMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* REACTIVATE MEMBER */
        builder.addCase(reactivateMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(reactivateMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.members = state.members.map(member => member.id.equals(action.payload.id) ? action.payload : member);
            if (state.memberSelected?.id.equals(action.payload.id)) {
                state.memberSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(reactivateMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* DEACTIVATE MEMBER */
        builder.addCase(deactivateMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deactivateMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.members = state.members.map(member => member.id.equals(action.payload.id) ? action.payload : member);
            if (state.memberSelected?.id.equals(action.payload.id)) {
                state.memberSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(deactivateMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* ACCEPT MEMBER */
        builder.addCase(acceptMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(acceptMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.members = state.members.map(member => member.id.equals(action.payload.id) ? action.payload : member);
            if (state.memberSelected?.id.equals(action.payload.id)) {
                state.memberSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(acceptMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* CREATE MEMBER */
        builder.addCase(createMemberThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(createMemberThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.members = [action.payload, ...state.members];
            state.status = 'ready';
        }).addCase(createMemberThunk.rejected, (state) => {
            state.status = 'error';
        });

        /* GET MEMBER QUOTA */
        builder.addCase(getMemberQuotaThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getMemberQuotaThunk.fulfilled, (state, action: PayloadAction<MemberPayment[]>) => {
            state.memberSelectedQuota = action.payload;
            state.status = 'ready';
        });

        /* UPDATE FACTORY CARD */
        builder.addCase(updateFactoryCardThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateFactoryCardThunk.fulfilled, (state, action: PayloadAction<Member>) => {
            state.memberSelected = action.payload;
            state.status = 'ready';
        });

        /* FIND FAMILY MEMBERS */
        builder.addCase(findFamilyMembersThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findFamilyMembersThunk.fulfilled, (state, action: PayloadAction<Member[]>) => {
            state.memberSelectedFamily = action.payload;
            state.status = 'ready';
        });

        /* FIND MEMBER INVITATIONS */
        builder.addCase(findMemberInvitationsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findMemberInvitationsThunk.fulfilled, (state, action: PayloadAction<Invitation[]>) => {
            state.memberInvitations = action.payload;
            state.status = 'ready';
        })

        /* SEND INVITATION */
        builder.addCase(sendInvitationThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(sendInvitationThunk.fulfilled, (state, action: PayloadAction<Invitation>) => {
            state.memberInvitations = [...state.memberInvitations, action.payload];
            if (state.memberSelected) {
                state.memberSelected = Member.fromJson({
                    ...state.memberSelected.toJson(),
                    availableInvitations: state.memberSelected.availableInvitations - 1
                });
            }
            state.status = 'ready';
        });
    }
});

const {
    changeFilterLimit,
    changeFilterOffset,
    changeFilterFromDate,
    changeFilterToDate,
    changeFilterSearchText,
    changeFilterStatus,
    changeAction,
    changeCreateMemberPass,
    selectMember,
    changePaymentMethod
} = memberSlice.actions;

const getMemberFilters = (state: RootState) => state.member.filters;
const getMemberInvitations = (state: RootState) => state.member.memberInvitations;
const getAction = (state: RootState) => state.member.action;
const getCreateMemberPass = (state: RootState) => state.member.createMemberPass;
const getFamilyMembers = (state: RootState) => state.member.memberSelectedFamily;
const getMembers = (state: RootState) => state.member.members;
const getMemberQuota = (state: RootState) => state.member.memberSelectedQuota;
const getMemberSelected = (state: RootState) => state.member.memberSelected;
const getStatus = (state: RootState) => state.member.status;
const getTotal = (state: RootState) => state.member.total;
const getPendingMembersCount = (state: RootState) => state.member.pendingMembersCount;


export {
    changeFilterLimit,
    changeFilterFromDate,
    changeFilterToDate,
    changeFilterOffset,
    changeFilterSearchText,
    changeFilterStatus,
    changePaymentMethod,

    changeAction,
    changeCreateMemberPass,
    selectMember,

    getAction,
    getCreateMemberPass,
    getFamilyMembers,
    getMembers,
    getMemberFilters,
    getMemberInvitations,
    getMemberQuota,
    getMemberSelected,
    getPendingMembersCount,
    getStatus,
    getTotal
}

export default memberSlice.reducer;