import { SepaPaymentMethodEmail } from "../value_objects/SepaPaymentMethodEmail";
import { SepaPaymentMethodId } from "../value_objects/SepaPaymentMethodId";
import { SepaPaymentMethodLast4 } from "../value_objects/SepaPaymentMethodLast4";
import { SepaPaymentMethodName } from "../value_objects/SepaPaymentMethodName";
import { CardPaymentMethodBrand } from "../value_objects/CardPaymentMethodBrand";
import { CardPaymentMethodYear } from "../value_objects/CardPaymentMethodYear";
import { CardPaymentMethodMonth } from "../value_objects/CardPaymentMethodMonth";
import { CardPaymentMethodType } from "../value_objects/CardPaymentMethodType";
import { CardPaymentMethodDisplayBrand } from "../value_objects/CardPaymentMethodDisplayBrand";

export interface CardPaymentMethodJson {
    id: string;
    email?: string;
    name?: string;
    last4?: string;
    brand?: string;
    display_brand?: string;
    exp_year?: string;
    exp_month?: string;
    type?: string;
}

export class CardPaymentMethod {
    constructor(
        readonly id: SepaPaymentMethodId,
        readonly email?: SepaPaymentMethodEmail,
        readonly name?: SepaPaymentMethodName,
        readonly last4?: SepaPaymentMethodLast4,
        readonly brand?: CardPaymentMethodBrand,
        readonly display_brand?: CardPaymentMethodDisplayBrand,
        readonly exp_year?: CardPaymentMethodYear,
        readonly exp_month?: CardPaymentMethodMonth,
        readonly type?: CardPaymentMethodType
    ) { }

    public toJson(): CardPaymentMethodJson {
        return {
            id: this.id.value,
            email: this.email?.value,
            name: this.name?.value,
            last4: this.last4?.value,
            brand: this.brand?.value,
            display_brand: this.display_brand?.value,
            exp_month: this.exp_month?.value,
            exp_year: this.exp_year?.value,
            type: this.type?.value
        };
    }

    static fromJson(json: CardPaymentMethodJson): CardPaymentMethod {
        return new CardPaymentMethod(
            new SepaPaymentMethodId(json.id),
            json.email ? new SepaPaymentMethodEmail(json.email) : undefined,
            json.name ? new SepaPaymentMethodName(json.name) : undefined,
            json.last4 ? new SepaPaymentMethodLast4(json.last4) : undefined,
            json.brand ? new CardPaymentMethodBrand(json.brand) : undefined,
            json.display_brand ? new CardPaymentMethodDisplayBrand(json.display_brand) : undefined,
            json.exp_year ? new CardPaymentMethodYear(json.exp_year) : undefined,
            json.exp_month ? new CardPaymentMethodMonth(json.exp_month) : undefined,
            json.type ? new CardPaymentMethodType(json.type) : undefined
        );
    }
}