import { SepaPaymentMethodEmail } from "../value-objects/SepaPaymentMethodEmail";
import { SepaPaymentMethodId } from "../value-objects/SepaPaymentMethodId";
import { SepaPaymentMethodLast4 } from "../value-objects/SepaPaymentMethodLast4";
import { SepaPaymentMethodName } from "../value-objects/SepaPaymentMethodName";
import { SepaPaymentMethodType } from "../value-objects/SepaPaymentMethodType";
import { SepaPaymentMethodExpYear } from "../value-objects/SepaPaymentMethodExpYear";
import { SepaPaymentMethodExpMonth } from "../value-objects/SepaPaymentMethodExpMonth";


export interface SepaPaymentMethodJson {
    id: string,
    email: string,
    name: string,
    last4: string,
    type?: string,
    exp_year: string,
    exp_month: string
}

export class SepaPaymentMethod {
    constructor(
        readonly id: SepaPaymentMethodId,
        readonly email: SepaPaymentMethodEmail,
        readonly name: SepaPaymentMethodName,
        readonly last4: SepaPaymentMethodLast4,
        readonly type: SepaPaymentMethodType,
        readonly exp_year: SepaPaymentMethodExpYear,
        readonly exp_month: SepaPaymentMethodExpMonth

    ) { }

    public toJson(): SepaPaymentMethodJson {
        return {
            id: this.id.value,
            email: this.email.value,
            name: this.name.value,
            last4: this.last4.value,
            type: this.type.value,
            exp_year: this.exp_year.value,
            exp_month: this.exp_month.value
        }
    }

    static fromJson(json: SepaPaymentMethodJson): SepaPaymentMethod {
        return new SepaPaymentMethod(
            new SepaPaymentMethodId(json.id),
            new SepaPaymentMethodEmail(json.email),
            new SepaPaymentMethodName(json.name),
            new SepaPaymentMethodLast4(json.last4),
            new SepaPaymentMethodType(json.type),
            new SepaPaymentMethodExpYear(json.exp_year),
            new SepaPaymentMethodExpMonth(json.exp_month)
        );
    }
}