import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from '../../../config/http';
import { MemberId } from '../../../features/member/domain/value-objects/MemberId';
import { Invitation } from '../domain/entities/Invitation';
import { Member } from '../domain/entities/Member';
import { MemberPayment } from '../domain/entities/MemberPayment';


import { CreateMemberDto, DeleteMemberDto, MemberFiltersDto, UnsubscribeMemberDto, UpdateFactoryCardDto, UpdateMemberDto } from './memberDto';
import { findInvitationsByMemberIdAsync, sendInvitationAsync } from './invitationService';

export const createMemberThunk = createAsyncThunk(
    'member/create',
    async (createMember: CreateMemberDto): Promise<Member> =>
        Member.fromJson(await postRequest('/members', createMember))
);

export const countMembersThunk = createAsyncThunk(
    'members/count',
    async (findMembersDto: MemberFiltersDto): Promise<Number> => {
        const response = await getRequest('/members/count', JSON.parse(JSON.stringify(findMembersDto)));
        return parseInt(response.total);
    }
)

export const countPendingMembersThunk = createAsyncThunk(
    'members/count_pending',
    async (): Promise<Number> => {
        const response = await getRequest('/members/count', JSON.parse(JSON.stringify({ "status": "pending" })));
        return parseInt(response.total);
    }
)

export const deleteMemberThunk = createAsyncThunk(
    'member/delete',
    async (dto: DeleteMemberDto) =>
        Member.fromJson(await deleteRequest(`/members/${dto.memberId}`, {
            rejectedReason: dto.rejectedReason
        }))
);

export const getMembersThunk = createAsyncThunk(
    'member/fetch',
    async (findMembersDto: MemberFiltersDto): Promise<Member[]> => {
        return Member.fromJsonArray(await getRequest('/members', JSON.parse(JSON.stringify(findMembersDto))));
    }
);

export const getMemberQuotaThunk = createAsyncThunk(
    'member/quota',
    async (memberId: MemberId) =>
        MemberPayment.fromJsonArray(await getRequest(`/members/${memberId.value}/quota`))
);


export const updateMemberThunk = createAsyncThunk(
    'member/update',
    async (updateMember: UpdateMemberDto): Promise<Member> => {
        //borramos los nulls y las cadenas vacías para pasar los validadores de la ruta en el back
        // Excepto las notas
        let notes: string = updateMember.notes;
        Object.keys(updateMember).forEach(k => (updateMember[k] !== 0 && !updateMember[k] && updateMember[k] !== undefined) && delete updateMember[k]);
        if (notes !== undefined) {
            updateMember.notes = notes;
        }
        return Member.fromJson(await patchRequest(`/members`, updateMember))
    }
);

export const findMemberByIdThunk = createAsyncThunk(
    'member/find_by_id',
    async (memberId: MemberId): Promise<Member> =>
        Member.fromJson(await getRequest(`/members/${memberId.value}`))
);

export const findMemberInvitationsThunk = createAsyncThunk(
    'member/find_invitations',
    async (memberId: MemberId): Promise<Invitation[]> => findInvitationsByMemberIdAsync(memberId.value)
)

export const exportMembersToCsvThunk = createAsyncThunk(
    'member/export',
    async (): Promise<File> => getRequest(`/members/export`)
);

export const updateFactoryCardThunk = createAsyncThunk(
    'member/update_factory_card',
    async ({ id, factoryCardUrl }: UpdateFactoryCardDto): Promise<Member> => {
        return Member.fromJson(await patchRequest(`/members`, {
            id,
            factoryCardUrl
        }))
    }
);

export const reactivateMemberThunk = createAsyncThunk(
    'member/reactivate',
    async (memberId: MemberId): Promise<Member> => {
        return Member.fromJson(await patchRequest(`/members/${memberId.value}/reactivate`, {
        }))
    }
);

export const deactivateMemberThunk = createAsyncThunk(
    'member/deactivate',
    async (dto: UnsubscribeMemberDto): Promise<Member> => {
        return Member.fromJson(await patchRequest(`/members/${dto.memberId}/deactivate`, {
            unsubscribeReason: dto.unsubscribeReason
        }))
    }
);

export const acceptMemberThunk = createAsyncThunk(
    'member/accept',
    async (memberId: MemberId): Promise<Member> => {
        return Member.fromJson(await patchRequest(`/members/${memberId.value}/accept`, {
        }))
    }
);

export const findFamilyMembersThunk = createAsyncThunk(
    'member/family_member',
    async (memberId: MemberId): Promise<Member[]> =>
        Member.fromJsonArray(await getRequest(`members/${memberId.value}/family_members`))
)

export const getMemberPaymentClientSecretThunk = createAsyncThunk(
    'member/payment_client_secret',
    async (memberId: MemberId): Promise<String> => {
        var response = await getRequest(`members/${memberId.value}/payment_secret`);
        return response.clientSecret;
    }
)

interface SendInvitationParams {
    memberId: string;
    to: string;
}

export const sendInvitationThunk = createAsyncThunk(
    'member/send_invitation',
    async ({ memberId, to }: SendInvitationParams): Promise<Invitation> => sendInvitationAsync(memberId, to)
)

export const getPaymentIntentClientSecretThunk = createAsyncThunk(
    'member/payment_intent_secret',
    async ({ memberId, paymentId }: { memberId: string; paymentId: string }): Promise<string> => {
        const response = await getRequest(`/members/${memberId}/payment_intent`, { payment_id: paymentId });
        return response.clientSecret;
    }
);

export const getTotalPreviewThunk = createAsyncThunk(
    'payment/total_preview',
    async (memberId: string) => {
        const response = await getRequest(`/members/${memberId}/getTotalPreview`);
        return response;
    }
);
